import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ourwork',
  templateUrl: './ourwork.component.html',
  styleUrls: ['./ourwork.component.scss']
})
export class OurworkComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
