import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { LandingComponent } from './landing/landing.component';
import { OurworkComponent } from './ourwork/ourwork.component';
import { GogiraffeComponent } from './ourwork/gogiraffe/gogiraffe.component';
import { BlogComponent } from './blog/blog.component';
import { IndividualComponent } from './blog/individual/individual.component';

@NgModule({
  declarations: [
    AppComponent,
    LandingComponent,
    OurworkComponent,
    GogiraffeComponent,
    BlogComponent,
    IndividualComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
