import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { LandingComponent } from './landing/landing.component';
import { OurworkComponent } from './ourwork/ourwork.component';
import { GogiraffeComponent } from './ourwork/gogiraffe/gogiraffe.component';
import { BlogComponent } from './blog/blog.component';
import { IndividualComponent } from './blog/individual/individual.component';

const routes: Routes = [
  {path: '', component: LandingComponent},
  {path: 'our-work', component: OurworkComponent},
  {path: 'our-work/gogiraffe', component: GogiraffeComponent},
  {path: 'blog', component: BlogComponent},
  {path: 'blog/individual', component: IndividualComponent}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
